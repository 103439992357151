import React, {useState, useEffect} from 'react'
import withRouter from '../../utils/withRouter'
import intl from 'react-intl-universal';
import {Input, Button, Spin, message} from 'antd';
import * as Api from '../../service/https'
import '../../asset/css/Account.scss'
import CommonTable from "../CommonTable";
import {delRedAddress, editRedAddress, getRAddressList, getRedAddressList} from "../../service/https";

const SampleDeliveryAddress = (props) => {
    const [pageIndex] = useState(1);
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([])
    const [information, setInformation] = useState({contactName: '', postCode: '', phone: '', address: ''})
    const changeFormData = (key, val) => {
        let obj = JSON.parse(JSON.stringify(information))
        obj[key] = val
        setInformation(obj)
    }
    const dataSource = [
        {
            key: 5,
            name: '刘德华',
            mobile: 32,
            postcode: '048000',
            address: '西湖区湖底公园1号',
            default:'默认地址',
            defaultAddress:false
        },
    ];


    const [uid,setUid] = useState(0)
    const getUserInfo = () => {
        Api.getUserInfo().then(res => {
            if(res.code === 200) {
                setUid(res.data.uid)
            }
        })
    }
    useEffect(() => {
        getUserInfo()
    },[])

    const getAddressList = () => {
        setLoading(true)
        Api.getRedAddressList({uid}).then(res => {
            if (res.code === 200) {
                setList(res.data)
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        if(uid) getAddressList()
    },[uid,pageIndex])

    const addAddress = () => {
        Api.addRedAddress({defaultAddress:false,uid,...information}).then(res => {
            if (res.code === 200) {
                message.success(intl.get('SampleDeliveryAddress')['添加成功'])
                getAddressList()
            }
        })
    }

    const columns = [
        {
            title: intl.get('SampleDeliveryAddress')['收件人姓名'],
            dataIndex: 'contactName',
            key: 'contactName',
            editable: true,
            width:200,
        },
        {
            title: intl.get('SampleDeliveryAddress')['收件人手机号'],
            dataIndex: 'phone',
            key: 'phone',
            editable: true,
            width:200,
        },
        {
            title: intl.get('SampleDeliveryAddress')['邮编'],
            dataIndex: 'postCode',
            key: 'postCode',
            editable: true,
            width:160
        },
        {
            title: intl.get('SampleDeliveryAddress')['具体地址'],
            dataIndex: 'address',
            key: 'address',
            editable: true,
        },
        {
            title: ' ',
            dataIndex: 'default',
            key: 'default',
            render:(value, record)=>{
                return <div onClick={()=> setDefaultAddress(value,record)}
                    style={{
                    cursor:'pointer',
                    display: 'inline-block',
                    borderRadius: '4px',
                    padding: '10px 20px',
                    color: record.defaultAddress ? '#F7385B' : '#333333',
                    border: record.defaultAddress ? '1px solid #F7385B' : 'none',
                    background: record.defaultAddress ? '#FFEEF1' : ''
                }}>{intl.get('SampleDeliveryAddress')['默认地址']}
                </div>
            }
        }
    ]

    const setDefaultAddress = (value,data) =>{
        const index = list.findIndex((item) => data.id === item.id);
        let item = Object.assign(list[index])
        data.defaultAddress = true
        editAddress(item)
    }

    const deleteAddress = (data) =>{
        Api.delRedAddress({id:data}).then(res => {
            if (res.code === 200) {
                message.success(intl.get('SampleDeliveryAddress')['删除成功'])
                getAddressList()
            }
        })
    }

    const editAddress = (data) =>{
        Api.editRedAddress(data).then(res => {
            if (res.code === 200) {
                message.success(intl.get('SampleDeliveryAddress')['修改成功'])
                getAddressList()
            }
        })
    }

    const isCanSubmit = () =>{
        return Object.values(information).findIndex(vm => !vm) == -1
    }

    return <div>
        <div className='flex-jb' style={{
            flexWrap: 'wrap',
            paddingLeft: '50px',
            paddingRight: '175px',
            paddingTop: '50px',
            position: 'relative'
        }}>
            <div style={{marginTop: '20px'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{
                        width: '120px',
                        fontWeight: 500,
                        textAlign: 'right',
                        paddingRight: '20px',
                        color: '#888888',
                        letterSpacing: '1px'
                    }}><span style={{color:'#F7385B'}}>*</span>{intl.get('SampleDeliveryAddress')['收件人姓名']}</div>
                    <Input onChange={e => changeFormData('contactName', e.target.value)} value={information.contactName}
                           style={{width: '430px', height: '48px', borderRadius: '4px'}}
                           placeholder={intl.get('ReleaseDemand')['请输入收件人姓名']}/>
                </div>
            </div>
            <div style={{marginTop: '16px'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{
                        width: '120px',
                        fontWeight: 500,
                        textAlign: 'right',
                        paddingRight: '20px',
                        color: '#888888',
                        letterSpacing: '1px'
                    }}><span style={{color:'#F7385B'}}>*</span>{intl.get('SampleDeliveryAddress')['邮编']}</div>
                    <Input onChange={e => changeFormData('postCode', e.target.value)} value={information.postCode}
                           style={{width: '430px', height: '48px', borderRadius: '4px'}}
                           placeholder={intl.get('SampleDeliveryAddress')['请输入邮编']}/>
                </div>
            </div>
            <div style={{marginTop: '16px'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{
                        width: '120px',
                        fontWeight: 500,
                        textAlign: 'right',
                        paddingRight: '20px',
                        color: '#888888',
                        letterSpacing: '1px'
                    }}><span style={{color:'#F7385B'}}>*</span>{intl.get('SampleDeliveryAddress')['收件人手机号']}</div>
                    <Input onChange={e => changeFormData('phone', e.target.value)}
                           value={information.phone}
                           style={{width: '430px', height: '48px', borderRadius: '4px'}}
                           placeholder={intl.get('ReleaseDemand')['请输入收件人手机号']}/>
                </div>
            </div>
            <div style={{marginTop: '16px'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{
                        width: '120px',
                        fontWeight: 500,
                        textAlign: 'right',
                        paddingRight: '20px',
                        color: '#888888',
                        letterSpacing: '1px'
                    }}><span style={{color:'#F7385B'}}>*</span>{intl.get('SampleDeliveryAddress')['具体地址']}</div>
                    <Input onChange={e => changeFormData('address', e.target.value)} value={information.address}
                           style={{width: '430px', height: '48px', borderRadius: '4px'}}
                           placeholder={intl.get('SampleDeliveryAddress')['请输入具体的收样品地址']}/>
                </div>
            </div>
            <Button style={{
                width: '210px', height: '48px', background: isCanSubmit() ? 'linear-gradient(180deg, #F7385B, #FF716E)' : '#EEEEEE',
                color: '#FFF', borderRadius: '4px', borderRight: '4px', letterSpacing: '1px', border: 0,
                margin: '32px auto 0'
            }} onClick={addAddress} disabled={!isCanSubmit()}>{intl.get('SampleDeliveryAddress')['确认添加地址']}</Button>
        </div>
        {/*提示*/}
        <div className='flex-start' style={{
            alignItems: 'center',
            color: '#F7385B',
            background: '#FFEEF1',
            padding: '12px 36px',
            borderRadius: '4px',
            margin: '48px 56px 0'
        }}>
            <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/tishi.png" alt=""
                 style={{width: '14px', height: '14px', marginRight: '17px'}}/>
            {list.length < 0 ? intl.get('SampleDeliveryAddress')['还未添加地址，最多能添加5条地址'] : `${intl.get('SampleDeliveryAddress')['已添加']}${list.length}${intl.get('SampleDeliveryAddress')['条地址，还能添加']}${5 - list.length}${intl.get('SampleDeliveryAddress')['条地址']}`}
        </div>
        {/*列表*/}
        <div style={{padding: '24px 56px'}}>
            {/*<div style={{*/}
            {/*    width: '100%',*/}
            {/*    height: '50px',*/}
            {/*    display: 'flex',*/}
            {/*    alignItems: 'center',*/}
            {/*    backgroundColor: '#F6F6F6',*/}
            {/*    fontWeight: 500*/}
            {/*}}>*/}
            {/*    <div style={{width: '15%', paddingLeft: '48px'}}>收件人姓名</div>*/}
            {/*    <div style={{width: '15%', paddingLeft: '15px'}}>收件人手机号</div>*/}
            {/*    <div style={{width: '10%', paddingRight: '10px'}}>邮编</div>*/}
            {/*    <div style={{width: '25%', paddingRight: '10px'}}>具体地址</div>*/}
            {/*    <div style={{width: '20%', paddingRight: '10px'}}>操作</div>*/}
            {/*    <div style={{width: '15%', paddingRight: '10px'}}></div>*/}
            {/*</div>*/}
            <Spin tip="数据加载中..." spinning={loading}>
                {/*<div>*/}
                {/*    {list.map((item,index) =>{*/}
                {/*        return <div key={index} style={{marginBottom: '10px', background: '#fff'}}>*/}
                {/*            <div key={index} style={{*/}
                {/*                width: '100%', height: '100px', display: 'flex', alignItems: 'center',*/}
                {/*                backgroundColor: index % 2 == 0 ? '#FFFFFF' : '#F6F6F6', cursor: 'pointer'*/}
                {/*            }}>*/}
                {/*                <div style={{*/}
                {/*                    width: '15%',*/}
                {/*                    paddingLeft: '48px',*/}
                {/*                    cursor: item?.productDetail?.productStatus === 1 ? 'pointer' : ''*/}
                {/*                }}>*/}
                {/*                    111*/}
                {/*                </div>*/}
                {/*                <div style={{width: '15%', paddingLeft: '15px'}}>18351035539</div>*/}
                {/*                <div style={{width: '10%', paddingRight: '15px'}}>048000</div>*/}
                {/*                <div style={{*/}
                {/*                    width: '25%',*/}
                {/*                    paddingRight: '15px'*/}
                {/*                }}>{item.productQuantity || '-'}</div>*/}
                {/*                <div style={{width: '20%', color: '#F7385B', display: 'flex'}}>*/}
                {/*                    <div className='flex-ac' style={{*/}
                {/*                        cursor: 'pointer',*/}
                {/*                        marginRight: '20px'*/}
                {/*                    }}>*/}
                {/*                        <img*/}
                {/*                            src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/mygoods/bianji.png'*/}
                {/*                            style={{*/}
                {/*                                width: 14,*/}
                {/*                                height: 14,*/}
                {/*                                marginRight: 5,*/}
                {/*                                cursor: 'pointer'*/}
                {/*                            }}/>*/}
                {/*                        <div style={{flexShrink: '0'}}>修改</div>*/}
                {/*                    </div>*/}
                {/*                    <div className='flex-ac' style={{cursor: 'pointer'}}>*/}
                {/*                        <img*/}
                {/*                            src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/mygoods/shanchu.png'*/}
                {/*                            style={{*/}
                {/*                                width: 14,*/}
                {/*                                height: 14,*/}
                {/*                                marginRight: 5,*/}
                {/*                                cursor: 'pointer'*/}
                {/*                            }}/>*/}
                {/*                        <div style={{flexShrink: '0'}}>删除</div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                <div style={{width: '15%', paddingRight: '15px'}}>*/}
                {/*                    <div style={{*/}
                {/*                        display: 'inline-block',*/}
                {/*                        borderRadius: '4px',*/}
                {/*                        padding: '10px 20px',*/}
                {/*                        color: index == 0 ? '#F7385B' : '#333333',*/}
                {/*                        border: index == 0 ? '1px solid #F7385B' : 'none',*/}
                {/*                        background: index == 0 ? '#FFEEF1' : ''*/}
                {/*                    }}>默认地址*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    })*/}
                {/*    }*/}
                {/*</div>*/}
                {
                    !loading ? <CommonTable list={list} columns={columns} isEdit={true} editIndex={-1} saveData={editAddress} deleteData={deleteAddress} /> : ''
                }
            </Spin>
        </div>
    </div>
}

export default withRouter(SampleDeliveryAddress);