import React, {useState, useEffect, useRef} from 'react'
import withRouter from '../../utils/withRouter'
import {Form, Select, Button} from "antd";
import intl from "react-intl-universal";
import ImageMagnifier from "../ImageMagnifier";
import UploadVideo from "./UploadVideo";
import Enum from '../../utils/Enum/Enum'
import * as Api from "../../service/https";

const AddMyWorks = (props) => {
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    const {Option} = Select;
    const [typeList,setTypeList] = useState([])
    const [purposeList,setPurposeList] = useState([
        {val: '商品销售', enVal:'merchandise sales', id:4,icon:''},
        {val: '品牌传播', enVal:'brand communication', id:2, icon:''},
        {val: '商品种草', enVal:'commodity grass', id:3, icon:''},
        {val: '商品好评', enVal:'goods high praise', id:5,icon:''},
        {val: '开箱视频', enVal:'out of the video', id:6,icon:''},
    ])
    const [mediaList,setMediaList] = useState([
        {val: 'TikTok', id:1,icon:''},
        {val: 'Youtube', id:2, icon:''},
        {val: 'Facebook', id:3, icon:''},
        {val: 'Instagram', id:4,icon:''},
    ])

    const getCargo = ()=>{
        Api.getCargo().then(res=>{
            if(res.code == 200){
                let all = res.data
                setTypeList(all)
            }
        })
    }
    const subData = Object.assign({},props.subData)
    const worksForm = useRef(null)
    useEffect(()=>{
        getCargo()
    },[])
    useEffect(()=>{
        let data = Object.assign({},subData)
        worksForm.current.setFieldsValue(data)
    },[props.subData])
    const changeTypeData = ((key, value) => {
        let data = Object.assign({}, subData)
        data[key] = value
        console.log(data);
        props.callbackParent(data)
    })
    const changeVideoUrl = fileData => {
        let data = Object.assign({}, subData)
        data.oldId = subData.fileId
        data.fileId = fileData.id
        data.fileAddress = fileData.fileAddress
        props.callbackParent(data)
    }
    const formItemLayout = {
        // labelCol: {
        //     xs: { span: 24 },
        //     sm: { span: 6 },
        // },
        // wrapperCol: {
        //     xs: { span: 24 },
        //     sm: { span: 14 },
        // },
    };
    return <div id='AddMyWorks'>
        <div style={{color: '#333333', fontSize: '18px', textAlign: 'center'}}>{intl.get('MyExcellentWorks')['上传我的优秀作品']}</div>
        <Form ref={worksForm}  {...formItemLayout} style={{marginTop: '52px'}} onFinish={props.callBackSubForm}>
            <div style={{marginTop: '24px', marginBottom: '36px'}}>
                <div style={{fontWeight: 600, marginBottom: '10px', marginRight: '14px'}}><span
                    style={{color: '#F7385B', marginRight: '3px', fontWeight: 'bold', fontSize: '17px'}}>*</span>
                    {intl.get('MyExcellentWorks')['视频']}（{intl.get('MyExcellentWorks')['视频格式支持mp4']}）
                </div>
                <UploadVideo maxCount={1} videoUrl={subData.fileAddress} canEditVideo={props?.subData?.updateTime ? false : true} callbackParent={changeVideoUrl}/>
            </div>
            <Form.Item name='cargoClassificationId'  label={intl.get('MyExcellentWorks')['所属分类']} id='WorkType' style={{marginTop: '24px'}}
                       rules={[{ required: true, message: `${intl.get('MyExcellentWorks')['请选择所属分类！']}`}]}>
                <Select size='large'
                    placeholder={<span
                        style={{fontSize: '14px'}}>{intl.get('MyExcellentWorks')['请选择所属分类']}</span>}
                    onChange={(val, e) => changeTypeData('cargoClassificationId', e?.id || '')} value={subData?.cargoClassificationId || ''}
                >
                    {
                        typeList.map((item, index) => {
                            return <Option key={index} id={item.id} value={item.id} label={item.goodsName}>
                                <div style={{textAlign: 'left'}}>
                                    {lang=='zh' ? item.goodsName :item.goodsEnglishName}
                                </div>
                            </Option>
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item name='targetEnum' label={intl.get('MyExcellentWorks')['视频用途']} id='WorkTypePurpose' style={{marginTop: '24px'}}
                       rules={[{ required: true, message: `${intl.get('MyExcellentWorks')['请选择视频用途！']}`}]}>
                <Select size='large'
                        placeholder={<span
                            style={{fontSize: '14px'}}>{intl.get('MyExcellentWorks')['请选择视频用途']}</span>}
                        onChange={(val, e) => changeTypeData('targetEnum', e?.id || '')} value={subData?.targetEnum || ''}
                >
                    {
                        purposeList.map((item, index) => {
                            return <Option key={index} id={item.id} value={item.id} label={item.val}>
                                <div style={{textAlign: 'left'}}>
                                    {item[lang=='zh' ? 'val' : 'enVal']}
                                </div>
                            </Option>
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item name='mediaEnum' label={intl.get('MyExcellentWorks')['传播媒体']} id='WorkTypeMedia' style={{marginTop: '24px'}}
                       rules={[{ required: true, message: `${intl.get('MyExcellentWorks')['请选择传播媒体！']}`}]}>
                <Select size='large'
                        placeholder={<span
                            style={{fontSize: '14px'}}>{intl.get('MyExcellentWorks')['请选择传播媒体']}</span>}
                        onChange={(val, e) => changeTypeData('mediaEnum', e?.id || '')} value={subData?.mediaEnum || ''}
                >
                    {
                        mediaList.map((item, index) => {
                            return <Option key={index} id={item.id} value={item.id} label={item.val}>
                                <div style={{textAlign: 'left'}}>
                                    {item.val}
                                </div>
                            </Option>
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item>
                <div className='flex fot-btn'>
                    <Button onClick={(()=>{props.callBackSetShowForm(false)})}>{intl.get('MyExcellentWorks')['取消']}</Button>
                    <Button type="primary" htmlType="submit">{intl.get('MyExcellentWorks')['确定']}</Button>
                </div>
            </Form.Item>
        </Form>
    </div>
}
export default AddMyWorks;