import React, { useState, useEffect, useRef } from 'react'
import withRouter from '../../../utils/withRouter'
import {Input, message, Select, Checkbox, Image, Spin, Divider, List, Popover, InputNumber, DatePicker, Space} from 'antd';
import { DownSquareTwoTone, UpSquareTwoTone } from '@ant-design/icons';
import * as Api from '../../../service/https';
import '../../../asset/css/MyGoods.scss'
import intl from 'react-intl-universal';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Option} from "antd/es/mentions";
import moment from 'moment';
import store from '../../../utils/redux/index'
import openNotificationWithIcon from '../../../components/Notification'
import {cancelDistributionProduct, collectDistribute, collectDistribution} from "../../../service/https";
import toKMB from "../../../utils/KMB";
import GoodsSelect from "../../../components/Goods/GoodsSelect";

const GoodsLists = (props) => {
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    const [state, setSate] = useState(store.getState());
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    const [textJson, textJsonSet] = useState(intl.get('GoodsLists'))
    const [goods_lists, goods_listsSet] = useState([])
    const [tabShow, setTabShow] = useState(false)
    const [goodsType, goodsTypeSet] = useState([])
    const [tabIndex, setTabIndex] = useState(-1)
    const [sortList, setSortList] = useState([
        intl.get('GoodsLists')['零售价'],
        intl.get('GoodsLists')['上架时间'],
        intl.get('GoodsLists')['佣金率']
    ]);
    const [sortIndex, setSortIndex] = useState(1);
    const [pageIndex, setPageIndex] = useState(1);
    const [total, setTotal] = useState(0);
    const [orderField, orderFieldSet] = useState(2);
    const [sortWay, sortWaySet] = useState(2);
    const [loading, setLoading] = useState(false);
    const [isBig, setBig] = useState(false)
    const [widths, setWidths] = useState(0)
    const [price_index, price_indexSet] = useState(-1)
    const [time_index, time_indexSet] = useState(-1)
    const [priceMin, priceMinSet] = useState()
    const [priceMax, priceMaxSet] = useState()
    const [monthMin, monthMinSet] = useState()
    const [monthMax, monthMaxSet] = useState()

    // 佣金率
    const [sortCommissionIndex, setCommissionIndex] = useState(-1)
    const [commissionList, setCommissionInput] = useState([
        { start: 0, key: '',vmEnd:'0.000001'},
        { start: 1, key: '~', end: 10 },
        { start: 10, key: '~', end: 20 },
        { start: 20, key: '~', end: 30 },
        { start: 30, key: '~', end: 40 },
        { start: 40, key: '~', end: 50 },
        { start: 50, key: '~', end: 60 },
        { start: 60, key: '<', end: 70 },
        { start: 70, key: '<', end: 80 },
        { start: '', key: '~', end: '' },
        { start: '', key: '~', end: '' },
        { start: '', key: '~', end: '' }
    ]);
    const [commissionConfirmStatus, setCommissionConfirmStatus] = useState(1)
    const changeCommissionMinInput = (index, value, param) => {
        let v = JSON.parse(JSON.stringify(commissionList))
        v[index].start = value > 80 ? 80 : value
        setCommissionInput(v)
    }
    const changeCommissionMaxInput = (index, value, param) => {
        let v = JSON.parse(JSON.stringify(commissionList))
        v[index].end = value > 80 ? 80 : value
        setCommissionInput(v)
    }
    const changeCommissionSearch = (index) => {
        setPageIndex(1)
        if (index == undefined || index < 9) {
            let v = JSON.parse(JSON.stringify(commissionList))
            v[9] = { start: '', key: '~', end: '' }
            setCommissionInput(v)
        }else{
            setCommissionConfirmStatus(commissionConfirmStatus == 1 ? 2 : 1)
        }
        setCommissionIndex(index == undefined ? -2 : index)
        if(document.getElementById('SelectCommission')) document.getElementById('SelectCommission').blur()
    }

    // 上架时间
    const [sortTimeIndex, setTimeIndex] = useState(-1)
    const [timeStart, setTimeStart] = useState('')
    const [timeEnd, setTimeEnd] = useState('')
    const [timeConfirmStatus, setTimeConfirmStatus] = useState(0)
    const timeList = [
        { start: 1, key: '个月', pre:'近'},
        { start: 1, key: '~', end: 3, pre:'' },
        { start: 3, key: '~', end: 6, pre:'' },
        { start: 6, key: '~', end: 12, pre:'' },
        { start: 12, key: '个月', pre:'大于'},
    ]

    // 商品价格
    const [priceConfirmStatus, setPriceConfirmStatus] = useState(1)
    const [sortPriceIndex, setPriceIndex] = useState(-1)
    const [priceList, setPriceInput] = useState([
        { key: '', end: 100, pre:'≤'},
        { start: 100, key: '~', end: 200, pre:'' },
        { start: 200, key: '~', end: 500, pre:'' },
        { start: 500, key: '~', end: 1000, pre:'' },
        { start: 1000, key: '', pre:'≥'},
        { start: '', key: '~', end: '', pre:'' },
        { start: '', key: '~', end: '', pre:'' },
        { start: '', key: '', end: '', pre:'' }
    ])
    
    const price_list = [
        {
        priceMin: '0',
        priceMax: '100',
        name: '≤¥100'
    }, {
        priceMin: '100',
        priceMax: '200',
        name: '¥100~¥200'
    }, {
        priceMin: '200',
        priceMax: '500',
        name: '¥200~¥500'
    }, {
        priceMin: '500',
        priceMax: '1000',
        name: '¥500~¥1000'
    }, {
        priceMin: '1000',
        priceMax: '0',
        name: '≥¥1000'
    }]

    const time_list = [
        {
        monthMin: '0',
        monthMax: '100',
        name: intl.get('GoodsLists')['≤1个月']
    }, {
        monthMin: '100',
        monthMax: '200',
        name: intl.get('GoodsLists')['1~3个月']
    }, {
        monthMin: '200',
        monthMax: '500',
        name: intl.get('GoodsLists')['3~6个月']
    }, {
        monthMin: '500',
        monthMax: '1000',
        name: intl.get('GoodsLists')['6~12个月']
    }, {
        monthMin: '1000',
        monthMax: '0',
        name: intl.get('GoodsLists')['≥12个月']
    }]

    useEffect(() => {
        getCategoryAll()
        heightFun()
        window.addEventListener('resize', heightFun)
        return () => {
            window.removeEventListener('resize', heightFun)
        }
    }, [])

    useEffect(() => {
        getCategoryList()
    }, [pageIndex, tabIndex, orderField, sortWay, price_index, time_index, sortTimeIndex, timeConfirmStatus, sortPriceIndex, sortCommissionIndex, priceConfirmStatus, commissionConfirmStatus])

    const heightFun = () => {
        let width = document.documentElement.clientWidth
        // let height = document.documentElement.clientHeight
        // console.log(height)
        let num = state.collapsed + (width > 1890 ? 6 : width > 1650 ? 5 : 4)
        setWidths(num)
    }

    const getCategoryAll = e => {
        Api.getCategoryAll().then(res => {
            if (res.code === 200) {
                goodsTypeSet(res.data)
            }
        })
    }

    const getCategoryList = e => {
        setLoading(true)
        let data = {
            productStatus: 1,
            categoryId1: tabIndex === -1 ? 0 : goodsType[tabIndex]?.id,
            current: pageIndex,
            size: 10,
            // priceMin: price_index === -1 ? 0 : price_index === -2 ? priceMin || 0 : price_list[price_index].priceMin,
            // priceMax: price_index === -1 ? 0 : price_index === -2 ? priceMax || 0 : price_list[price_index].priceMax,
            priceMin: sortPriceIndex == (-2 || -1) ? '' : priceList[sortPriceIndex]?.start || '',
            priceMax: sortPriceIndex == (-2 || -1) ? '' : priceList[sortPriceIndex]?.end || '',
            // monthMin: time_index === -1 ? 0 : time_index === -2 ? monthMin || 0 : time_list[time_index].monthMin,
            // monthMax: time_index === -1 ? 0 : time_index === -2 ? monthMax || 0 : time_list[time_index].monthMax,
            commissionPercentMin: sortCommissionIndex == (-2 || -1) ? '' : commissionList[sortCommissionIndex]?.start || '',
            commissionPercentMax: sortCommissionIndex == (-2 || -1) ? '' : commissionList[sortCommissionIndex]?.end ||  commissionList[sortCommissionIndex]?.vmEnd || '',
            orderField: orderField,
            sortWay: sortWay
        }
        if(!timeConfirmStatus){
            data.monthMin = timeList[sortTimeIndex]?.start || 0
            data.monthMax = timeList[sortTimeIndex]?.end || 0
        } else {
            if(timeConfirmStatus && timeStart) data.dateMin = timeStart
            if(timeConfirmStatus && timeEnd)data.dateMax = timeEnd
        }
        Api.getCategoryList(data).then(res => {
            if (res.code === 200) {
                setTotal(res.data.total)
                goods_listsSet(pageIndex <= 1 ? res.data.items : [...goods_lists, ...res.data.items])
            }
            setLoading(false)
        })
    }

    const sortMethod = (index) => {
        setPageIndex(0)
        setSortIndex(index)
    }

    const changeTab = (index) => {
        setTabIndex(index)
    }

    const loadMoreData = () => {
        setPageIndex(pageIndex + 1)
    };

    const addCollection = (e, item, index) => {
        e.stopPropagation()
        let data = {
            productId: item.productDetail.productId
        }
        if (item.isCollect) {
            Api.cancelCollectProduct(data).then(res => {
                if (res.code === 200) {
                    openNotificationWithIcon('success', intl.get('GoodsLists')['取消成功！'])
                    let t = JSON.parse(JSON.stringify(goods_lists))
                    t[index].isCollect = !t[index].isCollect
                    goods_listsSet(t)
                }
            })
        } else {
            Api.collectProduct(data).then(res => {
                if (res.code === 200) {
                    openNotificationWithIcon('success', intl.get('GoodsLists')['收藏成功！'])
                    let t = JSON.parse(JSON.stringify(goods_lists))
                    t[index].isCollect = !t[index].isCollect
                    goods_listsSet(t)
                }
            })
        }
    }

    const changeDistribution = (e, item, index) => {
        e.stopPropagation()
        let data = {
            productId: item.productDetail.productId
        }
        if (item.isDistribute) {
            Api.cancelDistributeProduct(data).then(res => {
                if (res.code === 200) {
                    openNotificationWithIcon('success', intl.get('GoodsLists')['取消加入带货清单'])
                    let t = JSON.parse(JSON.stringify(goods_lists))
                    t[index].isDistribute = !t[index].isDistribute
                    goods_listsSet(t)
                }
            })
        } else {
            Api.collectDistribute(data).then(res => {
                if (res.code === 200) {
                    openNotificationWithIcon('success', intl.get('GoodsLists')['加入带货清单'])
                    let t = JSON.parse(JSON.stringify(goods_lists))
                    t[index].isDistribute = !t[index].isDistribute
                    goods_listsSet(t)
                }
            })
        }
    }

    const content = (e = []) => (
        <div>
            <div style={{ display: 'flex', background: '#f6f6f6', padding: '10px 60px' }}>
                <div style={{ width: 120, marginRight: '10px' }}>{textJson['商品属性']}</div>
                <div style={{ width: 120, marginRight: '10px' }}>{textJson['商品价格']}</div>
                <div style={{ width: 120 }}>{textJson['库存数量']}</div>
            </div>
            {
                e?.map((item = {}, index) => (
                    <div key={index} style={{ display: 'flex', padding: '12px 60px' }}>
                        <div style={{ width: 120, marginRight: '10px' }}>{item.var1}{item.var2 ? '-' + item.var2 : ''}{item.var3 ? '-' + item.var3 : ''}</div>
                        <div style={{ width: 120, marginRight: '10px' }}>${item.price}</div>
                        <div style={{ width: 120 }}>{item.quantity}</div>
                    </div>
                ))
            }
        </div>
    )

    const price_indexChange = (e, index) => {
        if (index === -2) {
            if (price_index === -2) {
                getCategoryList()
            } else {
                price_indexSet(-2)
            }
        } else {
            price_indexSet(e.target.checked ? index : -1)
        }
    }

    const time_indexChange = (e, index) => {
        if (index === -2) {
            if (time_index === -2) {
                getCategoryList()
            } else {
                time_indexSet(-2)
            }
        } else {
            time_indexSet(e.target.checked ? index : -1)
        }
    }

    const toGoodsDetail = item => {
        window.open(`/index/GoodsDetail/${item.productDetail.productId}`)
    }

    const toBusinessDetail = item => {
        window.open(`/index/BusinessDetail/${item.enterpriseInfo.userIdMerchant}`)
    }

    // 别表
    const SmallDiv = (item, index) => {
        return (
            <div key={index} style={{
                width: '100%', height: '100px', display: 'flex', alignItems: 'center',
                backgroundColor: index % 2 == 0 ? '#FFFFFF' : '#F6F6F6', cursor: 'pointer'
            }}>
                <div style={{ width: '7.5%', paddingLeft: '19px' }}>
                    {
                        index < 3 ?
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/' + (index + 1) + '.png'} alt="" style={{}} />
                            :
                            <div style={{ width: '32px', textAlign: 'center', fontWeight: 500 }}>{index + 1}</div>
                    }
                </div>
                <div style={{ width: '27%', display: 'flex', alignItems: 'center' }} onClick={e => toGoodsDetail(item)}>
                    <Image onClick={e => e.stopPropagation()} src={item?.productDetail?.productImgUrls[0] || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                        style={{ width: '60px', height: '60px', marginRight: '16px' }} preview={false} />
                    <div>
                        <div className='over' style={{ height: '25px', fontWeight: 'bold', fontSize: '16px', lineHeight: '25px' }}>{item?.productDetail?.productName}</div>
                    </div>
                </div>
                <div style={{ width: '18%', display: 'flex', alignItems: 'center', paddingRight: '10px' }} onClick={e => toBusinessDetail(item)}>
                    <Image onClick={e => e.stopPropagation()} src={item?.enterpriseInfo?.logo || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                        style={{ width: '60px', height: '60px', marginRight: '16px', borderRadius: '100px' }} preview={false} />
                    <div>
                        <div className='over' style={{ height: '25px', fontWeight: 'bold', fontSize: '16px', lineHeight: '25px' }}>
                            {item?.enterpriseInfo?.name}
                        </div>
                        <div style={{ color: '#888888', fontSize: '12px' }}>
                            {intl.get('GoodsLists')['主营行业类型：']}{item?.enterpriseInfo?.industryType}
                        </div>
                    </div>
                </div>
                <div style={{ width: '10%', display: 'flex' }}>
                    {
                        item?.productDetail?.variationList.length <= 1 ?
                            <div>
                                {item?.productDetail?.variationList[0].var1}
                            </div>
                            :
                            <Popover id='goodslists_p' content={e => content(item?.productDetail?.variationList)} trigger="hover" placement="rightTop">
                                <div style={{ borderBottom: '1px #333 solid' }}>{textJson['多个属性']}</div>
                            </Popover>
                    }
                </div>
                <div style={{ width: '8%' }}>${item?.productPrice}</div>
                <div style={{ width: '8%' }}>{item?.productQuantity}</div>
                <div style={{ width: '10%' }}>{item?.launchTime}</div>
                <div style={{ width: '8%', padding:'0 20px' }}>{item?.productDetail?.commissionPercent || 0}%</div>
                <div style={{ width: '10%' }}>
                    {
                        item.isCollect ?
                            <img
                                onClick={(e) => addCollection(e, item, index)}
                                src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/scs.png'}
                                alt=""
                            />
                            : <img
                                onClick={(e) => addCollection(e, item, index)}
                                src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/sc.png'}
                                alt=""
                            />
                    }
                    {
                        state.userInfo?.userType == 2 ?
                            (item.isDistribute ?
                            <img
                                style={{marginLeft:7}}
                                onClick={(e) => changeDistribution(e, item, index)}
                                src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/daihuo/daihuos.png'}
                                alt=""
                            />
                            : <img
                                style={{marginLeft:7}}
                                onClick={(e) => changeDistribution(e, item, index)}
                                src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/daihuo/daihuo.png'}
                                alt=""
                            />) : ''
                    }
                </div>
            </div>
        )
    }

    const BigDiv = (item, index) => {
        return (
            <div className='BigDivs' style={{
                width: '240px', backgroundColor: '#FFF', margin: '0 auto',
                marginBottom: widths == 6 ? '8px' : widths == 5 ? '12px' : widths == 4 ? '18px' : '', borderRadius: '4px',
                cursor: 'pointer'
            }}>
                <div onClick={e => toGoodsDetail(item)} style={{
                    width: '100%', position: 'relative', backgroundColor: 'rgb(246,246,246)',
                    borderTopLeftRadius: '4px', borderTopRightRadius: '4px',
                }}>
                    <Image src={item?.productDetail?.productImgUrls[0]} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                        style={{ width: '240px', height: '240px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }} onClick={e => e.stopPropagation()} />
                    <div style={{
                        width: '100%', height: '100%', backgroundColor: 'rgb(0,0,0,0.22)',
                        display: 'flex', flexFlow: 'column', justifyContent: 'space-between', position: 'absolute', top: 0,
                        borderTopLeftRadius: '4px', borderTopRightRadius: '4px',
                    }}>
                        <div style={{ textAlign: 'right', paddingTop: '14px', paddingRight: '10px' }}>
                            {
                                item.isCollect ?
                                    <img
                                        onClick={(e) => addCollection(e, item, index)}
                                        src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/SCBS.png'}
                                        alt=""
                                    />
                                    : <img
                                        onClick={(e) => addCollection(e, item, index)}
                                        src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/SCB.png'}
                                        alt=""
                                    />
                            }
                            {
                                state.userInfo?.userType == 2 ?
                                    (item.isDistribute ?
                                    <img
                                        style={{marginLeft:7}}
                                        onClick={(e) => changeDistribution(e, item, index)}
                                        src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/daihuo/daihuos.png'}
                                        alt=""
                                    />
                                    : <img
                                        style={{marginLeft:7}}
                                        onClick={(e) => changeDistribution(e, item, index)}
                                        src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/daihuo/daihuo.png'}
                                        alt=""
                                    />) : ''
                            }
                        </div>
                        <div style={{ width: '100%', padding: '20px 14px' }}>
                            <div style={{ width: '100%', display: 'flex', color: '#FFF', alignItems: 'center' }}>
                                <div style={{ height: '30px', fontWeight: 'bold', fontSize: '16px', lineHeight: '25px' }}>{item?.enterpriseInfo?.name}</div>
                            </div>
                            <div style={{ color: '#F6F6F6', opacity: '0.7', fontWeight: 400 }}>{intl.get('GoodsLists')['主营行业类型：']}{item?.enterpriseInfo?.industryType}</div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%', height: '120px', padding: '16px 14px' }}>
                    <div className='flex-jb' style={{ color: '#F7385B', fontWeight: '800', fontSize: '16px' }}>
                        ${item?.productPrice}
                        <div style={{color:'#333333',fontSize:12,fontWeight:400}}>{textJson['佣金率']}:{item?.productDetail?.commissionPercent || 0}%</div>
                    </div>
                    <div style={{ fontSize: '12px', color: '#888' }}>
                        {textJson['上架时间']}：{item?.launchTime}
                    </div>
                    <Divider dashed style={{ margin: '10px 0' }} />
                    <div style={{ display: 'flex', alignItems: 'center' }} onClick={e => toBusinessDetail(item)}>
                        <Image onClick={e => e.stopPropagation()} src={item?.enterpriseInfo?.logo || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                            style={{ width: '32px', height: '32px', marginRight: '16px', borderRadius: '100px' }} preview={false} />
                        <div className='over' style={{
                            fontSize: '12px', fontWeight: '800',
                            webkitLineClamp: 1, height: '19px', lineHeight: '19px'
                        }}>
                            {item?.enterpriseInfo?.name}
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    const sortChange = e => {
        if (e === orderField) {
            sortWaySet(sortWay === 1 ? 2 : 1)
        } else {
            sortWaySet(2)
            orderFieldSet(e)
        }
        setPageIndex(1)
    }

    return (
        <div id='GoodsLists'>
            <nav style={{ width: '100%', position: 'relative', fontWeight: 500 }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/nav.png'} alt=""
                    style={{ width: '100%' }} />
                <div style={{
                    width: '100%', height: '100%', position: 'absolute', top: 0, left: 0,
                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/logoB.png'} alt=""
                        style={{ width: '190px' }} />
                    <div style={{
                        width: '10px', height: '10px', backgroundColor: 'black', borderRadius: '5px',
                        margin: '0 20px'
                    }}></div>
                    <span style={{ fontSize: '20px' }}>{textJson['选品大厅']}</span>
                </div>
            </nav>

            {/*<div style={{*/}
            {/*    width: '100%', backgroundColor: '#FFF', paddingTop: '24px', paddingLeft: '48px', paddingBottom: '25px',*/}
            {/*    fontWeight: 500*/}
            {/*}}>*/}
            {/*    <div style={{ width: '100%', display: 'flex' }}>*/}
            {/*        <label style={{ width: '90px - 45px', marginTop: '6px' }}>*/}
            {/*            {textJson['商品类型：']}*/}
            {/*        </label>*/}
            {/*        <div style={{*/}
            {/*            width: 'calc(100% - 90px - 45px)', height: tabShow ? 'auto' : '34px', display: 'flex', flexWrap: 'wrap',*/}
            {/*            overflow: tabShow ? '' : 'hidden'*/}
            {/*        }}>*/}
            {/*            <span*/}
            {/*                className='tabItem'*/}
            {/*                style={{*/}
            {/*                    padding: '6px 10px',*/}
            {/*                    marginBottom: '5px',*/}
            {/*                    borderRadius: '4px',*/}
            {/*                    marginRight: '10px',*/}
            {/*                    cursor: 'pointer',*/}
            {/*                    color: tabIndex == -1 ? '#E94753' : '#333',*/}
            {/*                    background: tabIndex == -1 ? '#FFE7E9' : 'none',*/}
            {/*                }}*/}
            {/*                onClick={() => changeTab(-1)}*/}
            {/*            >*/}
            {/*                {intl.get('GoodsLists')['全部']}*/}
            {/*            </span>*/}
            {/*            {*/}
            {/*                goodsType.map((item, index) => (*/}
            {/*                    <span*/}
            {/*                        key={index}*/}
            {/*                        className='tabItem'*/}
            {/*                        style={{*/}
            {/*                            padding: '6px 10px',*/}
            {/*                            marginBottom: '5px',*/}
            {/*                            borderRadius: '4px',*/}
            {/*                            marginRight: '10px',*/}
            {/*                            cursor: 'pointer',*/}
            {/*                            color: index == tabIndex ? '#E94753' : '#333',*/}
            {/*                            background: index == tabIndex ? '#FFE7E9' : 'none',*/}
            {/*                        }}*/}
            {/*                        onClick={() => changeTab(index)}*/}
            {/*                    >*/}
            {/*                        {item.name}*/}
            {/*                    </span>*/}
            {/*                ))*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*        <div style={{ width: '45px', paddingTop: '8px' }}>*/}
            {/*            {*/}
            {/*                !tabShow ?*/}
            {/*                    <DownSquareTwoTone style={{ fontSize: '18px', cursor: 'pointer' }} twoToneColor='#CCCCCC'*/}
            {/*                        onClick={() => setTabShow(true)} />*/}
            {/*                    :*/}
            {/*                    <UpSquareTwoTone style={{ fontSize: '18px', cursor: 'pointer' }} twoToneColor='#CCCCCC'*/}
            {/*                        onClick={() => setTabShow(false)} />*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div style={{ width: '100%', lineHeight: '30px', display: 'flex', marginTop: '15px' }}>*/}
            {/*        <label style={{ width: '90px - 45px' }}>*/}
            {/*            {textJson['商品价格：']}*/}
            {/*        </label>*/}
            {/*        <div style={{*/}
            {/*            width: 'calc(100% - 90px - 45px)', display: 'flex', flexWrap: 'wrap',*/}
            {/*            overflow: tabShow ? '' : 'hidden'*/}
            {/*        }}>*/}
            {/*            {*/}
            {/*                price_list?.map((item, index) => (*/}
            {/*                    <Checkbox key={index} style={{ marginRight: '30px', width: '110px', marginBottom:'10px' }} checked={index === price_index} onChange={e => price_indexChange(e, index)}>*/}
            {/*                        {item.name}*/}
            {/*                    </Checkbox>*/}
            {/*                ))*/}
            {/*            }*/}
            {/*            <div style={{ display: 'flex', marginBottom:'10px' }}>*/}
            {/*                <div style={{ marginRight: '20px' }}>自定义</div>*/}
            {/*                <InputNumber controls={false} placeholder={intl.get('GoodsLists')['最小值']} style={{ width: 80 }} value={priceMin} onChange={e => priceMinSet(e)} />*/}
            {/*                <div style={{ margin: '0 5px' }}>~</div>*/}
            {/*                <InputNumber controls={false} placeholder={intl.get('GoodsLists')['最大值']} style={{ width: 80 }} value={priceMax} onChange={e => priceMaxSet(e)} />*/}
            {/*                <div*/}
            {/*                    style={{*/}
            {/*                        lineHeight: '32px',*/}
            {/*                        padding: '0 10px',*/}
            {/*                        fontSize: '12px',*/}
            {/*                        marginLeft: '10px',*/}
            {/*                        borderRadius: '3px',*/}
            {/*                        cursor: 'pointer',*/}
            {/*                        textAlign: 'center',*/}
            {/*                        color: '#FFF',*/}
            {/*                        background: 'linear-gradient(180deg, #F7385B, #FF716E)'*/}
            {/*                    }}*/}
            {/*                    onClick={e => price_indexChange(e, -2)}*/}
            {/*                >确定</div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div style={{ width: '100%', lineHeight: '30px', display: 'flex', marginTop: '15px' }}>*/}
            {/*        <label style={{ width: '90px - 45px' }}>*/}
            {/*            {textJson['上架时间：']}*/}
            {/*        </label>*/}
            {/*        <div style={{*/}
            {/*            width: 'calc(100% - 90px - 45px)', display: 'flex', flexWrap: 'wrap',*/}
            {/*            overflow: tabShow ? '' : 'hidden'*/}
            {/*        }}>*/}
            {/*            {*/}
            {/*                time_list?.map((item, index) => (*/}
            {/*                    <Checkbox key={index} style={{ marginRight: '30px', width: '110px', marginBottom:'10px' }} checked={index === time_index} onChange={e => time_indexChange(e, index)}>*/}
            {/*                        {item.name}*/}
            {/*                    </Checkbox>*/}
            {/*                ))*/}
            {/*            }*/}
            {/*            <div style={{ display: 'flex', marginBottom:'10px' }}>*/}
            {/*                <div style={{ marginRight: '20px' }}>自定义</div>*/}
            {/*                <InputNumber controls={false} placeholder={intl.get('GoodsLists')['最小值']} style={{ width: 80 }} value={monthMin} onChange={e => monthMinSet(e)} />*/}
            {/*                <div style={{ margin: '0 5px' }}>~</div>*/}
            {/*                <InputNumber controls={false} placeholder={intl.get('GoodsLists')['最大值']} style={{ width: 80 }} value={monthMax} onChange={e => monthMaxSet(e)} />*/}
            {/*                <div*/}
            {/*                    style={{*/}
            {/*                        lineHeight: '32px',*/}
            {/*                        padding: '0 10px',*/}
            {/*                        fontSize: '12px',*/}
            {/*                        marginLeft: '10px',*/}
            {/*                        borderRadius: '3px',*/}
            {/*                        cursor: 'pointer',*/}
            {/*                        textAlign: 'center',*/}
            {/*                        color: '#FFF',*/}
            {/*                        background: 'linear-gradient(180deg, #F7385B, #FF716E)'*/}
            {/*                    }}*/}
            {/*                    onClick={e => time_indexChange(e, -2)}*/}
            {/*                >确定</div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div style={{
                width: '100%', backgroundColor: '#FFF', paddingTop: '24px', paddingLeft: '48px', paddingBottom: '25px',
                fontWeight: 500
            }}>
                <div style={{ width: '100%', display: 'flex' }}>
                    <label style={{ width: '90px - 45px', marginTop: '6px' }}>
                        {textJson['商品类型：']}
                    </label>
                    <div style={{
                        width: 'calc(100% - 90px - 45px)', height: tabShow ? 'auto' : '34px', display: 'flex', flexWrap: 'wrap',
                        overflow: tabShow ? '' : 'hidden'
                    }}>
                        <span
                            className='tabItem'
                            style={{
                                padding: '6px 10px',
                                marginBottom: '5px',
                                borderRadius: '4px',
                                marginRight: '10px',
                                cursor: 'pointer',
                                color: tabIndex == -1 ? '#E94753' : '#333',
                                background: tabIndex == -1 ? '#FFE7E9' : 'none',
                            }}
                            onClick={() => changeTab(-1)}
                        >
                            {intl.get('GoodsLists')['全部']}
                        </span>
                        {
                            goodsType.map((item, index) => (
                                <span
                                    key={index}
                                    className='tabItem'
                                    style={{
                                        padding: '6px 10px',
                                        marginBottom: '5px',
                                        borderRadius: '4px',
                                        marginRight: '10px',
                                        cursor: 'pointer',
                                        color: index == tabIndex ? '#E94753' : '#333',
                                        background: index == tabIndex ? '#FFE7E9' : 'none',
                                    }}
                                    onClick={() => changeTab(index)}
                                >
                                    {lang === 'zh' ? item.name : item.enName}
                                </span>
                            ))
                        }
                    </div>
                    <div style={{ width: '45px', paddingTop: '8px' }}>
                        {
                            !tabShow ?
                                <DownSquareTwoTone style={{ fontSize: '18px', cursor: 'pointer' }} twoToneColor='#CCCCCC'
                                                   onClick={() => setTabShow(true)} />
                                :
                                <UpSquareTwoTone style={{ fontSize: '18px', cursor: 'pointer' }} twoToneColor='#CCCCCC'
                                                 onClick={() => setTabShow(false)} />
                        }
                    </div>
                </div>
                <GoodsSelect pageIndex={pageIndex} priceList={priceList} sortPriceIndex={sortPriceIndex} changePageIndex={e =>{setPageIndex(e)}}
                             changePriceInput={e =>{setPriceInput(e)}}  changePriceIndex={e =>{setPriceIndex(e)}}
                             changePriceConfirmStatus={e =>{setPriceConfirmStatus(e)}} changeTimeStart={e => {setTimeStart(e)}} changeTimeEnd={e => {setTimeEnd(e)}}
                             changeTimeIndex={e => {setTimeIndex(e)}} changeTimeConfirmStatus={e => {setTimeConfirmStatus(e)}}
                             changeCommissionInput={e => {setCommissionInput(e)}} changeCommissionConfirmStatus={e => {setCommissionConfirmStatus(e)}}
                             changeCommissionIndex={e => {setCommissionIndex(e)}}/>
            </div>
            <div style={{
                width: '100%', height: '60px', backgroundColor: '#FFF', marginTop: '10px',
                display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '47px',
                paddingRight: '24px'
            }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ color: orderField === 1 ? '#F7385B' : '#333', lineHeight: '56px', cursor: 'pointer', marginRight: '33px' }} onClick={e => sortChange(1)}>
                        {intl.get('GoodsLists')['商品价格']}
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (orderField === 1 && sortWay == 1 ? 's' : '') + '.png'} alt=""
                            style={{ marginLeft: '6px', transform: 'rotate(180deg)' }} />
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (orderField === 1 && sortWay == 2 ? 's' : '') + '.png'} alt=""
                            style={{ marginLeft: '2px' }} />
                    </div>
                    <div style={{ color: orderField === 2 ? '#F7385B' : '#333', lineHeight: '56px', cursor: 'pointer', marginRight: '33px' }} onClick={e => sortChange(2)}>
                        {intl.get('GoodsLists')['上架时间']}
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (orderField === 2 && sortWay == 1 ? 's' : '') + '.png'} alt=""
                            style={{ marginLeft: '6px', transform: 'rotate(180deg)' }} />
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (orderField === 2 && sortWay == 2 ? 's' : '') + '.png'} alt=""
                            style={{ marginLeft: '2px' }} />
                    </div>
                    <div style={{ color: orderField === 7 ? '#F7385B' : '#333', lineHeight: '56px', cursor: 'pointer', marginRight: '33px' }} onClick={e => sortChange(7)}>
                        {intl.get('GoodsLists')['佣金率']}
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (orderField === 7 && sortWay == 1 ? 's' : '') + '.png'} alt=""
                             style={{ marginLeft: '6px', transform: 'rotate(180deg)' }} />
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (orderField === 7 && sortWay == 2 ? 's' : '') + '.png'} alt=""
                             style={{ marginLeft: '2px' }} />
                    </div>
                </div>
                <div>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/datu' + (isBig ? 's' : '') + '.png'} alt=""
                        style={{ cursor: 'pointer' }} onClick={() => setBig(true)} />
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/lb' + (!isBig ? 's' : '') + '.png'} alt=""
                        style={{ marginLeft: '6px', cursor: 'pointer' }}
                        onClick={() => setBig(false)} />
                </div>
            </div>

            <div style={{ width: '100%', backgroundColor: isBig ? '' : '#FFFFFF', padding: isBig ? '0 10px' : '24px 24px 24px 30px', marginTop: isBig ? 0 : '10px' }}>
                <div style={{ width: '100%', height: '50px', display: isBig ? 'none' : 'flex', alignItems: 'center', backgroundColor: '#F6F6F6', fontWeight: 500 }}>
                    <div style={{ width: '7.5%', paddingLeft: '19px' }}>{textJson['排名']}</div>
                    <div style={{ width: '27%', paddingLeft: '14px' }}>{textJson['商品']}</div>
                    <div style={{ width: '18%', paddingRight: '10px' }}>{textJson['商家']}</div>
                    <div style={{ width: '10%', paddingRight: '10px' }}>{textJson['商品属性']}</div>
                    <div style={{ width: '8%', paddingRight: '10px' }}>{textJson['商品价格']}</div>
                    <div style={{ width: '8%', paddingRight: '10px' }}>{textJson['库存数量']}</div>
                    <div style={{ width: '10%', paddingRight: '10px' }}>{textJson['上架时间']}</div>
                    <div style={{ width: '8%', padding: '0 20px' }}>{textJson['佣金率']}</div>
                    <div style={{ width: '10%', paddingRight: '10px' }}>{textJson['操作']}</div>
                </div>
                <Spin tip="数据加载中..." spinning={loading}>
                    <InfiniteScroll
                        dataLength={goods_lists.length}
                        scrollThreshold={'20px'}
                        next={loadMoreData}
                        hasMore={goods_lists.length < total}
                        // loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                        endMessage={<Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                        <List
                            dataSource={goods_lists}
                            grid={{
                                gutter: 0,
                                column: isBig ? widths : 1
                            }}
                            renderItem={
                                (item, index) =>
                                    <div style={{ paddingTop: isBig ? '10px' : 0 }}>
                                        {isBig ? BigDiv(item, index) : SmallDiv(item, index)}
                                    </div>
                            }
                        />
                    </InfiniteScroll>
                </Spin>
            </div>
        </div>
    )
}

export default withRouter(GoodsLists);